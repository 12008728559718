
.posts-list {
  padding-block: 24px;

  .ellipses {
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出容器部分隐藏 */
    text-overflow: ellipsis; /* 超出部分用省略号代替 */
  }

  .refresh {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(37, 38, 50, 0.6);
    line-height: 16px;
    cursor: pointer;
  }

  .item {
    margin-bottom: 16px;
  }

  .item-header {
    &_operation {
      ::v-deep .el-button {
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .medio-load {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #888888;
      line-height: 19px;
      cursor: pointer;
    }
  }

  .container-box {
    min-height: calc(100vh - 400px);

    .account-column {
      display: flex;
      align-items: center;
      gap: 3px;

      .account-img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .title-time-column {
      display: flex;
      align-items: center;
      gap: 5px;

      .title-time-img {
        width: 40px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
      }

      .title-time-info {
        display: flex;
        flex-direction: column;

        span.popover-span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #252632;
          text-align: left;
          font-style: normal;
          text-transform: none;
          cursor: pointer;
          display: inline-block;
          width: 250px;
        }

        .info-time {
          span,
          i {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            max-width: 41.5%;
            color: rgba(37, 38, 50, 0.6);
            text-align: left;
            font-style: normal;
          }

          & .icon-label {
            margin-left: 5px;
            color: #252632;
            padding: 1px 3px;
            border: 1px solid #f2f2f4;
          }
        }
      }
    }

    ::v-deep .el-scrollbar .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    ::v-deep(.el-scrollbar__bar.is-horizontal) {
      height: 0px;
    }

    ::v-deep(.el-scrollbar__bar.is-vertical) {
      width: 0px;
    }

    ::v-deep(.el-table__fixed-right::before),
    ::v-deep(.el-table__fixed::before) {
      background-color: transparent;
    }

    ::v-deep(.el-table td.el-table__cell),
    ::v-deep(.el-table th.el-table__cell.is-leaf) {
      border-bottom: none;
    }

    /* 自定义横向滚动条样式 */
    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar) {
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-track) {
      // background: black;
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: none;
      background: #dadbdd;
      border-radius: 5px;

      &:hover {
        display: block;
      }
    }

    ::v-deep(.el-table:hover .el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: block;
    }
  }
}
