
.posts-analysis {
  padding: 24px 0 0 0;

  .item-header {
    &_operation {
      ::v-deep .el-button {
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .item-content {
    background-color: #f2f2f4;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 24px;
    border: 1px solid #f2f2f4;
    border-bottom: none;
    border-right: none;

    .preview-button {
      width: 20%;
      height: 90px;
      box-sizing: border-box;
      background-color: #f9f9fa;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-inline: 16px;
      border-bottom: 1px solid #f2f2f4;
      border-right: 1px solid #f2f2f4;
      cursor: pointer;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;

        .el-icon-question {
          margin-left: 3px;
          font-size: 13px;
          line-height: 14px;
          font-style: normal;
          color: #bbbbbd;
          cursor: pointer;
        }
      }

      &_data {
        font-family: Douyin Sans, Douyin Sans;
        font-weight: bold;
        font-size: 20px;
        color: #252632;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      &.is-active {
        background-color: #fff;
        border-top: 2px solid #594fee;
      }
    }
  }

  .performance-content {
    margin-top: 20px;
    height: 455px;

    .item-brief-info {
      background-color: #f9f9fa;
      border-radius: 4px;
      padding: 16px 18px;
      display: flex;
      align-items: center;
      gap: 10px;

      &_type {
        display: flex;
        align-items: center;
        gap: 3px;
        flex-shrink: 0;
        i {
          font-size: 20px;
          color: #594fee;
          border-radius: 3px;
        }

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #594fee;
          line-height: 19px;
          font-style: normal;
        }
      }

      .imp-text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        max-width: 41.5%;
        color: rgba(37, 38, 50, 0.6);
        text-align: left;
        font-style: normal;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        white-space: nowrap;

        span.data {
          color: #252632;
          font-weight: 600;
        }

        span.tip {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .performance-chart {
      // height: calc(100% - 60px);
      margin-top: 12px;
      position: relative;

      ::v-deep .el-input__prefix {
        position: relative;
        left: 0;
        box-sizing: border-box;
        // border: 1px solid black;
        color: black;
        height: 25px;
        line-height: 25px;
        cursor: pointer;
      }

      ::v-deep .el-input__suffix {
        margin-left: 3px;
        position: relative !important;
      }

      ::v-deep .el-select {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
      }

      ::v-deep .el-input__inner {
        width: max-content;
        width: 0;
        height: 25px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #252632;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        border: none;
        padding: 0;
      }

      ::v-deep .el-input__icon {
        line-height: 25px;
      }

      .chart {
        width: 100%;
        height: 368px;
        padding: 16px;
        // margin-top: 10px;
        border: 1px solid #f2f2f4;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(28, 31, 35, 0.55);
        line-height: 16px;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}

// tooltip - echarts
::v-deep(.tooltip-date) {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #777880;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

::v-deep(.tooltip) {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #252632;
  line-height: 16px;
  font-style: normal;
  text-transform: none;
  img {
    width: 75px;
    height: 110px;
    border-radius: 6px;
    object-fit: cover;
  }

  .tooltip-info {
    width: 200px;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      margin-bottom: 5px;

      .item-text {
        width: 70%;
        & > div {
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }

        display: flex;
        align-items: center;
        gap: 5px;

        .item-icon {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
    }

    .tooltip-info_desc {
      font-weight: 600;
    }

    .tooltip-info_check {
      margin-left: 11px;
      font-size: 12px;
      color: #777880;
    }
  }
}
