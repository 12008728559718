
.content-analysis {
  padding: 24px;
  background-color: #fff;

  .header-box {
    /* element ui style */
    ::v-deep .el-menu-item {
      color: rgba(37, 38, 50, 0.6);
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      padding: 0 4px;
      margin-right: 24px;
      height: 50px;
      line-height: 50px;
      // line-height: 21px;
      font-weight: 400;
    }

    ::v-deep .el-menu-item.is-active {
      color: #252632;
    }
  }
}
