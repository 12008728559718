
.account-item {
  display: inline-block;
  // align-items: center;
  // gap: 3px;
  margin-left: 20px;
  img {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // background-color: black;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 3px;
    // background-color: black;
  }
}

::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected .account-item span {
  color: #409eff;
}

::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  left: 20px;
  top: 0;
}

.posts {
  // height: calc(100vh - 51px - 148px);
  padding: 24px 0 0 0;
  box-sizing: border-box;

  .content-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-menu {
      display: flex;
      align-items: center;
      gap: 8px;

      .menu-item {
        box-sizing: border-box;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: #f2f2f4;
        color: rgba(37, 38, 50, 0.8);
        font-family: PingFang SC, PingFang SC;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
        padding-inline: 18px;
        cursor: pointer;

        &.is-active {
          background-color: #ecebff;
          color: #594fee;
        }
      }

      span {
        margin-left: 8px;
        font-family: PingFang SC, PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: rgba(37, 38, 50, 0.6);
      }
    }

    .filter-box {
      display: flex;
      align-items: center;
      gap: 12px;

      .filter-item {
        background-color: #f2f2f4;
        border-radius: 4px;
        padding: 2px 12px;

        span {
          color: rgba(37, 38, 50, 0.6);
        }

        ::v-deep .el-select__tags-text {
          max-width: 180px !important; 
          flex-wrap: nowrap;
          display: inline-block;
          max-width: 100px !important; // 根据实际情况调整
          overflow: hidden; // 溢出隐藏
          text-overflow: ellipsis; // 超出文本以省略号显示
          white-space: nowrap; // 文本不换行
        }

        ::v-deep .el-input--suffix {
          width: 212px;
        }

        ::v-deep .el-input__inner {
          padding-left: 10px;
          background: transparent;
          border: none;
        }

        ::v-deep .el-date-editor .el-range-input {
          background-color: transparent;
        }

        ::v-deep .el-date-editor--daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
          width: 250px;
        }
      }
    }
  }

  ::v-deep .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #252632;
      display: flex;
      align-items: center;
      gap: 5px;

      .el-icon-question {
        margin-left: 3px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        font-style: normal;
        color: #bbbbbd;
        cursor: pointer;
      }
    }
  }
}
